var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-header",
        { staticClass: "pb-50" },
        [
          _c("h4", [_vm._v(" Users ")]),
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "check-button", switch: "", inline: "" },
              model: {
                value: _vm.showFilters,
                callback: function($$v) {
                  _vm.showFilters = $$v
                },
                expression: "showFilters"
              }
            },
            [_c("h5", [_vm._v(" Filters ")])]
          )
        ],
        1
      ),
      _vm.showFilters
        ? _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-md-0 mb-2",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("label", [_vm._v("Role")]),
                      _c("v-select", {
                        staticClass: "w-100",
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          value: _vm.roleFilter,
                          options: _vm.roleOptions,
                          reduce: function(val) {
                            return val.value
                          }
                        },
                        on: {
                          input: function(val) {
                            return _vm.$emit("update:roleFilter", val)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-md-0 mb-2",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("label", [_vm._v("Region")]),
                      _c("v-select", {
                        staticClass: "w-100",
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          value: _vm.regionFilter,
                          options: _vm.regionOptions,
                          reduce: function(val) {
                            return val.value
                          }
                        },
                        on: {
                          input: function(val) {
                            return _vm.$emit("update:regionFilter", val)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-md-0 mb-2",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("label", [_vm._v("Onboarding Status")]),
                      _c("v-select", {
                        staticClass: "w-100",
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          value: _vm.onboardingFilter,
                          options: _vm.onboardingOptions,
                          reduce: function(val) {
                            return val.value
                          }
                        },
                        on: {
                          input: function(val) {
                            return _vm.$emit("update:onboardingFilter", val)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mb-md-0 mb-2",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("label", [_vm._v("Status")]),
                      _c("v-select", {
                        staticClass: "w-100",
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          value: _vm.statusFilter,
                          options: _vm.statusOptions,
                          reduce: function(val) {
                            return val.value
                          }
                        },
                        on: {
                          input: function(val) {
                            return _vm.$emit("update:statusFilter", val)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }